import { render, staticRenderFns } from "./WeekContent.vue?vue&type=template&id=6570097c&scoped=true&"
import script from "./WeekContent.vue?vue&type=script&lang=js&"
export * from "./WeekContent.vue?vue&type=script&lang=js&"
import style0 from "./WeekContent.vue?vue&type=style&index=0&id=6570097c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6570097c",
  null
  
)

export default component.exports